'use client'

import React from 'react'
import Link from 'next/link'

interface ScrollLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string | undefined;
}

const ScrollLink: React.FC<ScrollLinkProps> = ({ href, children, className }) => {
  return (
    <Link href={href} className={className}>
      {children}
    </Link>
  )
}

export default ScrollLink